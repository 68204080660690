@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/OpenSans.eot);
    src: local('Open Sans'), local('OpenSans'),
        url(../../fonts/OpenSans.eot#iefix) format('embedded-opentype'),
        url(../../fonts/OpenSans.woff) format('woff'),
        url('../../fonts/OpenSans-Regular.ttf') format('truetype');
}
